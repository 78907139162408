import Highcharts from 'highcharts';
import borderRadius from 'highcharts-border-radius/index';
borderRadius(Highcharts);
import { TR_MONTH_NAMES } from '@/assets/js/functions/tr.month.names';

import Moment from "moment";
import MomentTimeZone from "moment-timezone";

window.moment = Moment;
MomentTimeZone();

const COLUMN_RADIUS = 6;

const HIGHCHARTS_COLORS = [
  '#2980B9',
  '#7d5fff',
  '#5B2C6F',
  '#99cccc',
  '#ff6633',
  '#fbc531',
  '#F79F1F',
  '#4b4b4b',
  '#3d3d3d',
  '#ff793f',
  '#a5b1c2',
  '#4b6584',
  '#2c2c54',
  '#84817a',
  '#81ecec'
];

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'Nunito, sans-serif'
    },
  },
  credits: {
    enabled: false
  },
  title: {
    text: ''
  },
  plotOptions: {
    pie: {
      colors: HIGHCHARTS_COLORS
    },
    column: {
      borderRadiusTopLeft: COLUMN_RADIUS,
      borderRadiusTopRight: COLUMN_RADIUS,
    },
  },
  colors: HIGHCHARTS_COLORS,
  lang: {
    months: TR_MONTH_NAMES
  },
  time: {
    timezone: 'Europe/Istanbul'
  }
});
